<template>
  <div class="page">
    <el-button type="text" size="medium" @click="onEdit">修改卡券</el-button>
    <!-- 编辑弹框 -->
    <el-dialog title="修改卡券" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="70%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="有效期限" prop="effType" class="long-content">
            <el-radio-group v-model="params.effType">
              <el-radio-button label="1">不限制</el-radio-button>
              <el-radio-button label="2">有效天数</el-radio-button>
              <el-radio-button label="3">有效日期</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="在购买后" prop="afterDayNum" v-if="params.effType == '2'">
            <el-input v-model.trim="params.afterDayNum" autocomplete="off" maxlength="10" placeholder="请填写有效天数">
              <template slot="append">天内有效</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="" v-if="params.effType == '3'">
            <template slot="label"><span style="color: red;margin-right: 4px;"> *</span>有效日期</template>
            <el-date-picker v-model="dateToDate" type="daterange" align="left" unlink-panels range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" clearable
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <template slot="label"><span style="color: red;margin-right: 4px;"> *</span>适用范围</template>
            <el-cascader v-model="params.roomIds" :options="roomList" :props="{ multiple: true, emitPath: false }"
              collapse-tags placeholder="请选择适用范围"></el-cascader>
          </el-form-item>
          <el-form-item label="预约限制" prop="enablePreLimit">
            <el-radio v-model="params.enablePreLimit" :label="false">可用,不限时长</el-radio>
          </el-form-item>
          <el-form-item label="续单限制" prop="enableRenewalLimit">
            <el-radio v-model="params.enableRenewalLimit" :label="false">可用,不限时长</el-radio>
          </el-form-item>
          <el-form-item label="适用时段" class="long-content">
            <ul class="card-date-ul">
              <li class="card-date-item" v-for="(item, index) in params.cardSuit" :key="index">
                <div>
                  <span>可用日期：</span>
                  <el-radio-group v-model="item.suitType">
                    <el-radio-button v-for="i in cardDateArr" :key="i.value" :label="i.value">{{ i.label
                      }}</el-radio-button>
                  </el-radio-group>
                </div>
                <div class="mt15" style="margin-left: 70px;" v-if="item.suitType == '7'">
                  <el-checkbox-group v-model="item.suitWeek">
                    <el-checkbox v-for="i in cardWeekArr" :key="i.value" :label="i.value">{{ i.label
                      }}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="other" v-if="item.suitType == '7'">
                  <el-select v-model="item.holidayContainType" style="width: 120px;">
                    <el-option label="不考虑" :value="1"></el-option>
                    <el-option label="不包含" :value="2"></el-option>
                    <el-option label="或者" :value="3"></el-option>
                  </el-select>
                  <span class="append">法定节假日</span>
                  <el-select v-model="item.workdayContainType" style="width: 120px;">
                    <el-option label="不考虑" :value="1"></el-option>
                    <el-option label="不包含" :value="2"></el-option>
                    <el-option label="或者" :value="3"></el-option>
                  </el-select>
                  <span class="append">补班</span>
                </div>
                <div class="mt15">
                  <span>可用时间：</span>
                  <el-time-select placeholder="起始时间" v-model="item.suitStartTime" :editable="false" :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                    maxTime: params.suitEndTime
                  }">
                  </el-time-select>
                  <span class="mr15 ml15">至</span>
                  <el-time-select placeholder="结束时间" v-model="item.suitEndTime" :editable="false" :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                  }">
                  </el-time-select>
                  <span class="ml15">最多任意连续小时数：</span>
                  <el-select v-model="item.continuousMaxHour" placeholder="请选择连续时常" clearable style="width: 180px;">
                    <el-option v-for="item in continuousMaxHourObj" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <span class="card-tips" v-if="item.suitType != '7'">{{ cardTipsObj[item.suitType] }}</span>
                <span class="card-tips" v-if="item.suitType == '7'">
                  <span>*该卡券</span>
                  <span v-if="item.suitWeek.length">{{ changeWeek(item.suitWeek) }},</span>
                  <span>{{ changeContain(item.holidayContainType, item.workdayContainType) }}</span>
                </span>
                <span class="card-tips">
                  <span v-if="item.suitStartTime == '00:00' && item.suitEndTime == '00:00'">全天可用</span>
                  <span v-else>{{ item.suitStartTime }}-{{ item.suitEndTime }}可用</span>
                  <span v-if="item.continuousMaxHour && item.continuousMaxHour != 0 && item.continuousMaxHour != 24">{{
                    item.continuousMaxHour
                  }}小时</span>
                </span>
              </li>
            </ul>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getCardOrderEdit } from "@/api/card/order";
import { rules } from "@/db/rules";
import { cardBgArr, pickerOptions, cardDateArr, cardWeekArr, cardTipsObj, continuousMaxHourObj } from "@/db/objs";
export default {
  props: ['cardSuitVos', "row", 'roomList'],
  data() {
    return {
      cardBgArr,
      pickerOptions,
      cardDateArr,
      cardWeekArr,
      cardTipsObj,
      continuousMaxHourObj,
      // 弹框
      editShow: false, //弹框开关
      // from表单
      rules,
      params: {
        memberCardId: "",
        effType: "1",//有效期限
        afterDayNum: "",//有效天数
        startTime: "",//有效开始
        endTime: "",//有效结束
        roomIds:[],//适用范围
        enablePreLimit: false,//预约限制
        enableRenewalLimit: false,//续单限制
        cardSuitVos: [
          {
            suitType: 1,//可用日期
            suitWeek: [],//可用星期
            holidayContainType: 1,//节假日包含类型
            workdayContainType: 1,//补班日包含类型
            suitStartTime: "",//开始时间
            suitEndTime: "",//结束时间
            continuousMaxHour: "",//最多任意连续小时数
          }
        ],
      },
      dateToDate: [],//有效时段
      // roomIdArr: [],
    }
  },
  watch: {
    dateToDate(val) {
      if (val) {
        this.params.startTime = val[0];
        this.params.endTime = val[1];
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
    // roomIdArr(val) {
    //   if (val) {
    //     this.params.roomIds = val.toString();
    //   } else {
    //     this.params.roomIds = "";
    //   }
    // }
  },
  methods: {
    // 【转换】星期
    changeWeek(arr) {
      let a = []
      let newArr = JSON.parse(JSON.stringify(arr)).sort((a, b) => {
        return a - b // 按升序排
      });
      this.cardWeekArr.forEach((i) => {
        newArr.forEach((j) => {
          if (i.value == j) {
            a.push(i.label);
          }
        });
      });
      return a.join(",")
    },

    // 【转换】节假日
    changeContain(a, b) {
      if (a == 1 && b == 1) {
        return ""
      }
      else if (a == 1 && b == 2) {
        return "(不包含补班)，"
      }
      else if (a == 1 && b == 3) {
        return "补班，"
      }
      else if (a == 2 && b == 1) {
        return "（不包含法定节假日），"
      }
      else if (a == 2 && b == 2) {
        return "（不包含补班和法定节假日），"
      }
      else if (a == 2 && b == 3) {
        return "补班（不包含法定节假日），"
      }
      else if (a == 3 && b == 1) {
        return "法定节假日，"
      }
      else if (a == 3 && b == 2) {
        return "法定节假日（不包含补班），"
      }
      else if (a == 3 && b == 3) {
        return "法定节假日、补班，"
      }
    },

    // 【转换】房间
    changeRoom(roomlist) {
      let arr = [];
      for (let i of roomlist) {
        arr.push(i.roomId)
      }
      return arr
    },

    // 【请求】卡券订单修改
    getCardOrderEdit() {
      let data = this.params;
      if (this.params.roomIds) {
        data.roomIds = data.roomIds.toString();
      }
      getCardOrderEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.$emit('reload')
        }
      });
    },

    // 【监听】编辑
    onEdit() {
      this.editShow = true;
      this.params = this.row;
      if (this.row.startTime && this.row.endTime) {
        this.dateToDate = [this.row.startTime, this.row.endTime];
      }
      this.params.roomIds = this.changeRoom(this.row.roomVos);
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.params.effType == '3'&&this.params.startTime==''){
            this.$message({
              message: "请选择有效日期",
              type: "warning",
            });
            return false;
          }
          if(this.params.roomIds==null||this.params.roomIds.length == 0){
            this.$message({
              message: "请选择适用范围",
              type: "warning",
            });
            return false;
          }
         
          this.getCardOrderEdit();
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: inline-flex;
}

.card-date-ul {
  outline: 2px dashed #eee;
  padding: .15rem;
}

.other {
  margin-left: .7rem;
  display: flex;
  align-items: center;
}

.append {
  display: inline-flex;
  width: .8rem;
  color: #909399;
  background: #f5f7fa;
  justify-content: center;
  margin-right: .15rem;
  outline: 1px solid #dcdfe6;
  height: .38rem;
}

.card-tips {
  color: #999;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>

<style lang="scss">
.long-content {
  .el-form-item__content {
    width: 80%;
  }
}
</style>
